const CloseIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.47505 3.13001L6.99979 6.49277L10.525 3.13001C10.6193 3.04 10.7723 3.04 10.8667 3.13001C10.9611 3.2201 10.9611 3.36607 10.8667 3.45609L7.34158 6.81885L10.8667 10.182C10.9611 10.272 10.9611 10.418 10.8667 10.5081C10.7723 10.5981 10.6193 10.5981 10.525 10.5081L6.99979 7.14493L3.47505 10.5081C3.3807 10.5981 3.2277 10.5981 3.13326 10.5081C3.03891 10.418 3.03891 10.272 3.13326 10.182L6.65801 6.81885L3.13326 3.45609C3.03891 3.36608 3.03891 3.22011 3.13326 3.13001C3.22769 3.04 3.38069 3.04 3.47505 3.13001Z"
        // fill="white"
        // stroke="white"
        stroke-width="0.75"
      />
    </svg>
  );
};

export default CloseIcon;
