import { useEffect, useState } from 'react';

const ConditionalWrapper = ({
  children,
  isConditional,
  filterDataLayer,
  blockName = 'Personalized Content Block',
}) => {
  const [isKnownUser, setIsKnownUser] = useState(false);

  // Function to update isKnownUser based on dataLayer values
  const updateDataLayerValue = () => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      const matches = filterDataLayer?.some((filter) => {
        const targetKey = filter?.parent_taxonomy_data?.name;
        const targetValue = filter?.name;

        return window.dataLayer.some((item) => {
          const dataLayerValue = item[targetKey];

          // Handle values separated by ";" or ","
          if (
            typeof dataLayerValue === 'string' &&
            (dataLayerValue.includes(';') || dataLayerValue.includes(','))
          ) {
            const dataLayerValuesArray = dataLayerValue
              .split(/[,;]/) // Split by either ',' or ';'
              .map((v) => v.trim());
            return dataLayerValuesArray.includes(targetValue);
          }

          // Default single-value comparison
          return dataLayerValue === targetValue;
        });
      });

      setIsKnownUser(matches);
    }
  };

  // Periodic check for dataLayer updates
  useEffect(() => {
    const intervalCheck = setInterval(() => {
      if (typeof window !== 'undefined' && window.dataLayer) {
        updateDataLayerValue();
      }
    }, 300);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalCheck);
    }, 3000);

    // Cleanup the interval when the component unmounts or when filterDataLayer changes
    return () => {
      clearInterval(intervalCheck);
      clearTimeout(timeoutId);
    };
  }, [filterDataLayer]);

  // Listen for new dataLayer pushes
  useEffect(() => {
    const dataLayerListener = (event) => {
      if (event?.detail) {
        updateDataLayerValue();
      }
    };

    window.addEventListener('dataLayerPush', dataLayerListener);
    return () => window.removeEventListener('dataLayerPush', dataLayerListener);
  }, [filterDataLayer]);

  // Push the `view_personalization_block` event when the block is rendered
  useEffect(() => {
    if (typeof window !== 'undefined' && window.dataLayer && isKnownUser) {
      const viewData = {
        event: 'view_personalization_block',
        p_type: blockName,
        page_location: window.location.href,
        page_referrer: document.referrer,
        page_title: document.title,
      };

      window.dataLayer.push(viewData);
      console.log('View Data pushed to dataLayer:', viewData);
    }
  }, [isKnownUser]);

  // Add click event listeners for CTA links
  useEffect(() => {
    const handleCTAClick = (event) => {
      if (isKnownUser && typeof window !== 'undefined' && window.dataLayer) {
        const ctaData = {
          event: 'click_personalization_block',
          p_type: blockName,
          p_button_text: event.target.innerText,
          p_button_link: event.target.href,
          page_location: window.location.href,
          page_referrer: document.referrer,
          page_title: document.title,
        };

        window.dataLayer.push(ctaData);
        console.log('CTA Click Data pushed to dataLayer:', ctaData);
      }
    };

    // Select all <a> tags with class "cta-link"
    const ctaLinks = document.querySelectorAll('a.cta-link');

    // Add event listeners
    ctaLinks.forEach((link) => {
      link.addEventListener('click', handleCTAClick);
    });

    // Cleanup listeners on unmount
    return () => {
      ctaLinks.forEach((link) => {
        link.removeEventListener('click', handleCTAClick);
      });
    };
  }, [isKnownUser]);

  // Render children if conditions are met
  if (!isConditional || (isConditional && isKnownUser)) {
    return children;
  }

  // Return null if the conditions are not met
  return null;
};

export default ConditionalWrapper;
