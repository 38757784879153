import { getGlossaryLanguage } from './DataUtil';

let host = 'https://087-YZJ-646.mktorest.com';

export function handleFocus(fields, glossary, lang) {
  const elementIds = [
    '#KR_Partner_Solution_Required__c',
    '#Power_Range__c',
    '#Racks_Required__c',
    '#Cloud_Service_Connectivity__c',
    '#Preferred_location__c',
    '#Renewal__c',
    '#KR_Partner_Type__c',
    '#Company_HQ_Country__c',
    '#KR_Partner_Lead_Primary_Contact__c',
    '#Industry',
    '.mktoHtmlText',
    '#Country',
    '#Status_Comments__c',
  ];

  elementIds.forEach((id) => {
    const element = document.querySelector(id);
    if (element) {
      const elementsToTranslate =
        id === '#Country'
          ? [element.options?.[0]]
          : id === '.mktoHtmlText'
            ? [element]
            : element.options || [element];

      translateElements(elementsToTranslate, glossary, lang);
    }
  });

  if (fields) {
    for (let items of fields) {
      for (const field of items) {
        field.addEventListener('focus', function (event) {
          const mktoError = document.querySelector('.mktoInvalid');
          const mktoErrorMsg = document.querySelectorAll('.mktoErrorMsg');
          const mktoRequiredLabel =
            document.querySelector('.mktoRequiredLabel');
          let nextElement = field.nextElementSibling;
          let nextOfNextElement = nextElement.nextElementSibling;
          if (mktoError) {
            translateElements(mktoErrorMsg, glossary, lang);
            // mktoError.classList.add('removeError');
          }

          if (event.target.type === 'email') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(event.target.value.trim())) {
              if (nextOfNextElement.className == 'mktoInstruction') {
                nextOfNextElement.style.cssText += 'display: none !important;';
              }
            } else {
              nextOfNextElement.style.cssText =
                nextOfNextElement.style.cssText.replace(
                  'display: none !important;',
                  ''
                );
            }
          }

          // if(mktoRequiredLabel){
          //     mktoRequiredLabel.style.display = 'none';
          // }

          event.stopPropagation();
          activeClass(event, fields);
        });

        field.addEventListener('keydown', function (event) {
          event.stopPropagation();

          if (!event.shiftKey) {
            activeClass(event, fields);

            const element = document.getElementById('Email');
            const count = element?.value?.length;

            if (count > 0) {
              const mktoError = document.querySelector('.mktoError');

              if (mktoError) {
                mktoError.style.display = 'none';
              }
            }
          }
        });

        field.addEventListener('focusout', function (event) {
          event.stopPropagation();
          removeActiveClass(fields);
          const mktoError = document.querySelector('.mktoInvalid');
          let nextElement = field.nextElementSibling;
          let nextOfNextElement = nextElement.nextElementSibling;
          let element = event.target;
          const parentElement = element.closest('.mktoFormCol');
          const mktoValid = document.querySelector('.mktoValid');
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

          if (
            (event.target?.value?.length === 0 &&
              event.target?.classList.contains('mktoRequired')) ||
            (mktoError && field.id === 'custom-dropdown')
          ) {
            event.target.classList.add('error');
            const mktoError = document.querySelector('.mktoInvalid');

            if (mktoError && mktoError && field.id !== 'custom-dropdown') {
              // mktoError.classList.add('removeError');
              parentElement.classList.add('removeError');
            }
            if (mktoValid) {
              mktoValid.classList.remove('mktoFilled');
              parentElement.classList.remove('mktoFilled');
            }
          } else if (event.target.type === 'email') {
            if (!emailPattern.test(event.target.value.trim())) {
              event.target.classList.add('error');
              parentElement.classList.add('removeError');
              parentElement.classList.remove('mktoFilled');

              if (nextOfNextElement.className == 'mktoInstruction') {
                nextOfNextElement.style.cssText += 'display: none !important;';
              }
            } else {
              event.target.classList.remove('error');
              parentElement.classList.remove('removeError');
              parentElement.classList.add('mktoFilled');
              nextOfNextElement.style.cssText =
                nextOfNextElement.style.cssText.replace(
                  'display: none !important;',
                  ''
                );
            }
          } else if (event.target.type === 'tel') {
            const phonePattern = /^\+?[0-9\s\-()]+$/;
            if (!phonePattern.test(event.target.value.trim())) {
              event.target.classList.add('error');
              parentElement.classList.add('removeError');
              parentElement.classList.remove('mktoFilled');
            } else {
              event.target.classList.remove('error');
              parentElement.classList.remove('removeError');
              parentElement.classList.add('mktoFilled');
            }
          } else {
            event.target.classList.remove('error');
            parentElement.classList.remove('removeError');

            if (mktoValid && event.target.type != 'checkbox') {
              mktoValid.classList.add('mktoFilled');
              parentElement.classList.add('mktoFilled');
            } else {
              mktoValid.classList.remove('mktoFilled');
              parentElement.classList.remove('mktoFilled');
            }
          }
          if (field.id == 'temporaryField2' && event.target.value == '') {
            field.classList.remove('error');
            parentElement.classList.remove('removeError');
          }
          if (field.id == 'formComments' && event.target.value == '') {
            field.classList.remove('error');
            parentElement.classList.remove('removeError');
          }
        });
      }
    }
  }
}

export function removeActiveClass(fields) {
  const button = document.querySelector('.marketo-form button');

  if (fields) {
    for (const items of fields) {
      for (const item of items) {
        const elem = item.parentElement.firstChild;

        if (
          item?.value?.length === 0 &&
          button !== document.activeElement &&
          item?.type !== 'select-one'
        ) {
          elem.classList.remove('active');
        }
      }
    }
  }
}

export function activeClass(event, fields) {
  removeActiveClass(fields);

  let prevSibling = event.target.previousElementSibling?.previousElementSibling;

  if (prevSibling?.nodeName === 'LABEL') {
    prevSibling.classList.add('active');
  }
}

export function autoPopulateAddActive(fields) {
  for (let items of fields) {
    for (const field of items) {
      if (field.labels !== null) {
        field.labels?.[0]?.classList.add('active');
        let prevSibling = field.previousElementSibling?.previousElementSibling;

        if (prevSibling?.nodeName === 'LABEL') {
          prevSibling.classList.add('active');
        }
      }
    }
  }
}

export function translateElements(data, glossary, lang) {
  if (!glossary || glossary.length === 0) {
    return;
  }

  function normalizeAndCompare(str1, str2) {
    // Replace various whitespace characters with regular spaces
    const normalizedStr1 = str1?.replace(/\s+/g, ' ').trim().toLowerCase();
    const normalizedStr2 = str2?.replace(/\s+/g, ' ').trim().toLowerCase();

    // Handle special characters and punctuation
    const cleanStr1 = normalizedStr1?.replace(/[^\w\s]/g, '');
    const cleanStr2 = normalizedStr2?.replace(/[^\w\s]/g, '');

    // Use locale-specific comparison for better Unicode support
    return cleanStr1.localeCompare(cleanStr2) === 0;
  }

  for (let item of data) {
    let innerText = item?.innerText;

    for (let word of glossary) {
      if (normalizeAndCompare(word['en'], innerText)) {
        item.innerHTML = word[getGlossaryLanguage(lang)];
        break; // Exit the loop once a match is found
      }
    }
  }
}

export function translateRecaptcha(data, glossary, lang) {
  if (!glossary || glossary.length === 0) {
    return;
  }

  // Ensure data and innerHTML are defined
  const innerHtml = data?.innerHTML;

  if (!innerHtml) {
    console.error('data.innerHTML is undefined');
    return; // Exit if innerHTML is not available
  }

  // Define normalizeWhitespace function outside the loop
  const normalizeWhitespace = (str) => str.replace(/\s+/g, ' ').trim();

  for (let word of glossary) {
    // Ensure word.en is defined
    if (word.en) {
      if (normalizeWhitespace(word.en) === normalizeWhitespace(innerHtml)) {
        // Check if the language property exists
        const translation = word[getGlossaryLanguage(lang)];
        if (translation) {
          data.innerHTML = translation;
        } else {
          console.error(
            `Translation for language ${lang} not found in glossary.`
          );
        }
        break; // Exit the loop once a match is found
      }
    } else {
      console.warn('word.en is undefined for a glossary entry:', word);
    }
  }
}

export function translatePlaceholders(inputs, glossary, lang) {
  if (!glossary || glossary.length === 0) {
    return;
  }

  function normalizeAndCompare(str1, str2) {
    const normalizedStr1 = str1?.replace(/\s+/g, ' ').trim().toLowerCase();
    const normalizedStr2 = str2?.replace(/\s+/g, ' ').trim().toLowerCase();
    const cleanStr1 = normalizedStr1?.replace(/[^\w\s]/g, '');
    const cleanStr2 = normalizedStr2?.replace(/[^\w\s]/g, '');

    return cleanStr1.localeCompare(cleanStr2) === 0;
  }

  inputs.forEach((input) => {
    let placeholderText = input?.getAttribute('placeholder');

    for (let word of glossary) {
      if (normalizeAndCompare(word['en'], placeholderText)) {
        input.setAttribute('placeholder', word[getGlossaryLanguage(lang)]);
        break;
      }
    }
  });
}

const validateForm = (fields) => {
  return Array.from(fields).every((field) => field.value?.trim() !== '');
};

export function handleChange(formId, event) {
  const buttons = document.querySelectorAll('#mktoForm_' + formId + ' button');
  const fields = document.querySelectorAll(
    '#mktoForm_' + formId + ' .mktoRequired'
  );
  const fields2 = document.querySelectorAll(
    '#mktoForm_' + formId + ' .mktoRequiredField'
  );
  let combinedFields = [...fields, ...fields2];

  if (formId === '2801') {
    combinedFields = combinedFields.filter(
      (field) => field.id !== 'formComments' && field.id !== 'temporaryField2'
    );
  }

  console.log(combinedFields);

  const isValid = validateForm(combinedFields);

  if (isValid) {
    // generateCaptchaToken();
    buttons[0]?.classList.toggle('active', isValid);
    buttons[0]?.classList.toggle('enable', isValid);
    // buttons[0].addEventListener('click', function () {
    //   this.disabled = true;
    // });
  } else {
    buttons[0]?.classList.toggle('disable', false);
    buttons[0]?.classList.remove('active');
    buttons[0]?.classList.remove('enable');
  }
}
export function handleChangeRegisterLead(formId) {
  const buttons = document.querySelectorAll('#mktoForm_' + formId + ' button');
  const fields = document.querySelectorAll(
    '#mktoForm_' + formId + ' .mktoRequired'
  );
  const fields2 = document.querySelectorAll(
    '#mktoForm_' + formId + ' .mktoRequiredField'
  );
  const combinedFields = [...fields, ...fields2];
  const isValid = validateForm(combinedFields);
  const form = window.MktoForms2?.getForm(formId);
  const formValues = form.vals();

  if (isValid && formValues.Pref_Optin_Pending__c === 'yes') {
    // generateCaptchaToken();
    buttons[0]?.classList.toggle('active', isValid);
    buttons[0]?.classList.toggle('enable', isValid);
    // buttons[0].addEventListener('click', function () {
    //   this.disabled = true;
    // });
  } else {
    buttons[0]?.classList.remove('enable');
    buttons[0]?.classList.remove('active');
  }
}

export function handleChangeModal(formId, event) {
  const buttons = document.querySelectorAll('#mktoForm_' + formId + ' button');

  let areCustomDropDownsFilled = false;

  const form = window.MktoForms2?.getForm(formId);
  const formVals = form.vals();

  if (
    validateFormWithChecks(
      formVals,
      ['honeyPotSubmission'],
      [
        (formId == '2788' || formId == '2786' || formId == '2801') &&
        formVals.Country === 'United States'
          ? 'State'
          : null,
      ]
    )
  ) {
    areCustomDropDownsFilled = true;
  } else {
    areCustomDropDownsFilled = false;
  }

  if (areCustomDropDownsFilled) {
    // generateCaptchaToken();
    buttons[0]?.classList.toggle('active', areCustomDropDownsFilled);
    buttons[0]?.classList.add('active');
    // buttons[0].addEventListener('click', function () {
    //   this.disabled = true;
    // });
  } else {
    buttons[0]?.classList.remove('active');
    buttons[0]?.classList.remove('enable');
  }
}

export function enableMarketoButton(formId, event, checked) {
  const buttons = document.querySelectorAll('#mktoForm_' + formId + ' button');
  buttons[0]?.classList.toggle('enable', checked);
  if (checked) {
    buttons[0]?.classList.add('active');
  } else {
    buttons[0]?.classList?.remove('active');
  }
}

export const validateFormWithChecks = (
  data,
  ignoreKeys = [],
  requiredKeys = []
) => {
  for (const key in data) {
    if (requiredKeys.includes(key) && !data[key]) {
      console.log(`Required key is missing or empty: ${key}`);
      return false;
    }
    if (!ignoreKeys.includes(key) && !data[key]) {
      console.log(`Missing value for key: ${key}`);
      return false;
    }
  }
  return true;
};

export const validateFormWithChecksCookie = (
  data,
  ignoreKeys = [],
  requiredFields = {}
) => {
  for (const key in requiredFields) {
    if (ignoreKeys.includes(key)) {
      console.log(`Ignoring key: ${key}`);
      continue;
    }

    if (
      !data.hasOwnProperty(key) ||
      data[key] === null ||
      data[key] === undefined ||
      data[key] === ''
    ) {
      console.log(`Missing or empty required field: ${key}`);
      return false;
    }
  }

  for (const key in data) {
    if (
      !ignoreKeys.includes(key) &&
      (data[key] === null || data[key] === undefined || data[key] === '')
    ) {
      console.log(`Invalid value for key: ${key}`);
      return false;
    }
  }

  return true;
};

export const generateCaptchaToken = () => {
  let allForms = window.MktoForms2.allForms();

  if (allForms.length > 1) {
    const secondForm = allForms[1]; // Get the second form

    // Remove the form's element from the DOM if it exists
    const secondFormEl = document.getElementById(secondForm.getId());
    if (secondFormEl) {
      secondFormEl.remove();
    }

    // Create a new array excluding the second form (index 1)
    const newFormsArray = allForms.slice(0, 1).concat(allForms.slice(2));

    // Override the MktoForms2.allForms() to return the updated array
    window.MktoForms2.allForms = () => newFormsArray;
  } else {
    delete window.MktoForms2;
  }
  if (window.MktoForms2) {
    const firstForm = window.MktoForms2.allForms()[0];

    if (firstForm) {
      const captchaElement = firstForm.getFormElem().find('.g-recaptcha');

      if (captchaElement) {
        grecaptcha
          .execute(captchaElement.data('widget-id'))
          .then((token) => {
            firstForm.addHiddenFields({ captchaToken: token });

            console.log('Token generisan i dodat u formu.');
          })
          .catch((error) => {
            console.error('Greška prilikom izvršavanja reCAPTCHA:', error);
          });
      }
    }
  }
};
